import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle62 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
        Verlaging van toltarief in Denemarken

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage62.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                20-12-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Deense toltarieven zullen met {" "}
            <span style={{color: "#2b5baa"}}>
            19% verlagen {" "}
            </span>
            ten opzichte van de eerder aangekondigde bedragen.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Denemarken blijft momenteel, samen met Nederland, een van de laatste landen die nog gebruik maakt van het {" "}
            <span style={{color: "#9c29ff"}}>
            Eurovignet
            </span>
            . Echter,{" "}
            <span style={{color: "#2b5baa"}}>
            vanaf 1 januari {" "}
            </span>
            stappen de Denen over op een{" "}
            <span style={{color: "#2b5baa"}}>
            kilometerheffing voor vrachtwagens 
            </span>
            , gebaseerd op CO2-uitstoot en gewichtsklasse. Al met al resulteert dit voor de meest vervuilende vrachtwagens in een{" "}
            <span style={{color: "#9c29ff"}}>
            besparing van ongeveer 3 cent per kilometer.  
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Overigens zullen de{" "}
            <span style={{color: "#2b5baa"}}>
            dieselprijzen in 2025{" "}
            </span>
            omgerekend met {" "}
            <span style={{color: "#2b5baa"}}>
            ca. 7 cent per liter toenemen
            </span>
            , waardoor de aangekondigde verlaging van de toltarieven een welkome compensatie is. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Houd er wel rekening mee dat in{" "}
            <span style={{color: "#9c29ff"}}>
            milieuzones een extra toeslag tot 7 cent per kilometer{" "}
            </span>
            geldt.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{color: "#2b5baa"}}>
            De tolbruggen{" "}
            </span>   
            in Denemarken en Zweden{" "}
            <span style={{color: "#2b5baa"}}>
            vallen buiten deze algemene toltarieven
            </span>   
            . Voor de{" "}
            <span style={{color: "#9c29ff"}}>
            Øresundbrug{" "}
            </span>  
            is al aangekondigd dat de tarieven met{" "}
            <span style={{color: "#9c29ff"}}>
            1,5% zullen stijgen
            </span> 
            ,  terwijl de{" "}
            <span style={{color: "#2b5baa"}}>
            Storebæltbrug {" "}
            </span>
            een verhoging van ongeveer {" "}
            <span style={{color: "#2b5baa"}}>
            2% zal doorvoeren
            </span>
            . Hierbij is het belangrijk op te merken dat de{" "}
            <span style={{color: "#9c29ff"}}>
            Øresundbrug met Zweedse BTW {" "}
            </span>
            wordt belast en de{" "}
            <span style={{color: "#2b5baa"}}>
            Storebæltbrug met Deense BTW
            </span>
            .  De toepassing van deze BTW zorgt veelal voor verwarring. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle62;
