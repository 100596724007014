import React from "react";
import NewsArticle62 from "../elements/NewsArticle62";
import NewsNav from "../elements/NewsNav";

const NewsDetails62 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle62 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails62;