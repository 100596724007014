import React from "react";
import NewsArticle63 from "../elements/NewsArticle63";
import NewsNav from "../elements/NewsNav";

const NewsDetails63 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle63 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails63;