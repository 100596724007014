import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle63 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
        Brennerpas verbiedt trucks op zaterdagen

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage63.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                16-01-25
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voor voertuigen met een gewicht van  {" "}
            <span style={{color: "#2b5baa"}}>
            meer dan 7,5 ton{" "}
            </span>
            geldt{" "}
            <span style={{color: "#2b5baa"}}>
            vanaf nu tot 15 maart {" "}
            </span>
            een aanvullend rijverbod op de{" "}
            <span style={{color: "#9c29ff"}}>
            A12 (Inntal Autobahn), A13 (Brennerpas) en A14 (Rheindal/Walgau)
            </span>
            . Deze maatregel is ingesteld vanwege onderhoudswerkzaamheden aan de Luegbrücke op de Brenner Autobahn (A13).
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Brenner Autobahn is met een jaarlijkse passage van ongeveer 2,5 miljoen 
            vrachtwagens en 14,3 miljoen auto’s een van de belangrijkste verbindingsroutes tussen Oostenrijk en Italië.  
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het verbod vormt de nieuwste beperking voor vrachtwagens in Tirol, naast {" "}
            <span style={{color: "#9c29ff"}}>
            bestaande maatregelen{" "}
            </span>
            zoals nachtverbod (tussen 22:00 en 05:00), zondagrijverboden en de Blockabfertigung op de A93 en A12, waarbij vrachtwagens op bepaalde dagen slechts in {" "}
            <span style={{color: "#2b5baa"}}>
            beperkte aantallen{" "}
            </span>
            mogen passeren. Hoewel Duitse en Italiaanse vervoerders, die veel gebruikmaken van deze route, al jaren hun onvrede uiten over de Oostenrijkse beperkingen, laten inmiddels ook lokale ondernemers van zich horen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{color: "#2b5baa"}}>
            Het rijverbod{" "}
            </span>
            is van kracht op alle zaterdagen van{" "}
            <span style={{color: "#2b5baa"}}>
            11 januari 2025 tot 15 maart
            </span>
            , tussen{" "}
            <span style={{color: "#2b5baa"}}>
            07.00 en 15.00 uur. {" "}
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Daarnaast wordt het verbod{" "}
            <span style={{color: "#9c29ff"}}>
            hervat op zaterdag 12 april{" "}
            </span>
            en geldt het op{" "}
            <span style={{color: "#9c29ff"}}>
            alle zaterdagen van 7 juni tot 27 september
            </span>
            . Verder geldt het verbod van{" "}
            <span style={{color: "#2b5baa"}}>
            07.00 tot 22.00 uur{" "}
            </span>
            op de volgende dagen: {" "}
            <span style={{color: "#2b5baa"}}>
            donderdag 17 april, vrijdag 18 april, woensdag 28 mei, woensdag 18 juni en donderdag 2 oktober. {" "}
            </span>
            
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Vrachtwagens{" "}
            <span style={{color: "#9c29ff"}}>
            mogen tussen 5.00 en 7.00 uur ‘s ochtends{" "}
            </span>   
            nog gebruikmaken van het traject. Echter, met een lengte van {" "}
            <span style={{color: "#2b5baa"}}>
            115 kilometer {" "}
            </span>   
            en een maximumsnelheid van {" "}
            <span style={{color: "#9c29ff"}}>
            60 kilometer per uur
            </span>  
            , wordt dit een race tegen de klok. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle63;
